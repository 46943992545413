
// Vue
import {Options, Vue} from "vue-class-component";

// Models
import {AppPlaceIndex} from "@/models/app/place";
import {ResponseIndex} from "@/models/response";

// Components
import {
  ElDialog,
  ElForm,
  ElFormItem,
  ElInput,
  ElSelect,
  ElOption,
  ElButton,
} from "element-plus";
import {
  Edit,
} from "@element-plus/icons-vue";

// Services
import {putRequest} from "@/services/api/request";
import {getTranslation} from "@/services/app/translation";

@Options({
  props: [
    "counties",
    "place",
  ],
  components: {
    ElDialog,
    ElForm,
    ElFormItem,
    ElInput,
    ElSelect,
    ElOption,
    ElButton,
    Edit,
  },
})
export default class AppAdministratorMasterDataPlacesListFormUpdateIndexVue extends Vue {
  isLoading = false;
  isDialogVisible = false;

  place: AppPlaceIndex = new AppPlaceIndex();

  formData: AppPlaceIndex = new AppPlaceIndex();
  formDataRules = {
    county_id: [
      {
        required: true,
        trigger: "blur",
      },
    ],
    content: {
      title: [
        {
          required: true,
          trigger: "blur",
        },
      ],
      postCode: [
        {
          required: true,
          trigger: "blur",
        },
      ],
    },
  };

  get translation(): any {
    return getTranslation([
      "choose",
      "county",
      "edit",
      "save",
      "title",
      "titlePlaceholderPlace",
      "postCode",
      "postCodePlaceholder",
    ]);
  }

  $refs!: {
    formComponent: HTMLFormElement;
  };

  validateForm(): void {
    this.$refs.formComponent.validate((response: any) => {
      if (response) {
        this.submitForm();
      }
    });
  }

  async submitForm(): Promise<void> {
    this.isLoading = true;
    await putRequest({
      uri: "/common/place/update",
      formData: this.formData,
      isProtected: true,
      isSuccessNotificationVisible: true,
      isErrorNotificationVisible: true,
    }).then((r: ResponseIndex) => {
      if (r.status === "success") {
        this.$emit('getPlaces');
        this.isDialogVisible = false;
        this.resetFormData();
      }
    });
    this.isLoading = false;
  }

  setFormData(): void {
    this.formData = this.place;
  }

  resetFormData(): void {
    this.formData = new AppPlaceIndex();
  }

  updated(): void {
    this.setFormData();
  }
}
