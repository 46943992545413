import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2100f4f6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app-administrator-master-data-places-list" }
const _hoisted_2 = { class: "box" }
const _hoisted_3 = { class: "box__headline" }
const _hoisted_4 = { class: "title" }
const _hoisted_5 = { class: "action" }
const _hoisted_6 = { class: "box__filter" }
const _hoisted_7 = {
  key: 0,
  class: "box__list"
}
const _hoisted_8 = { class: "item__content" }
const _hoisted_9 = { class: "item__content-name" }
const _hoisted_10 = { class: "item__action" }
const _hoisted_11 = {
  key: 0,
  class: "pagination"
}
const _hoisted_12 = {
  key: 1,
  class: "box__message"
}
const _hoisted_13 = {
  key: 2,
  class: "box__loading"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppAdministratorMasterDataPlacesListFormCreateIndexVue = _resolveComponent("AppAdministratorMasterDataPlacesListFormCreateIndexVue")!
  const _component_AppAdministratorMasterDataPlacesListFormFilterIndexVue = _resolveComponent("AppAdministratorMasterDataPlacesListFormFilterIndexVue")!
  const _component_AppAdministratorMasterDataPlacesListFormUpdateIndexVue = _resolveComponent("AppAdministratorMasterDataPlacesListFormUpdateIndexVue")!
  const _component_AppAdministratorMasterDataPlacesListFormRemoveIndexVue = _resolveComponent("AppAdministratorMasterDataPlacesListFormRemoveIndexVue")!
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _component_LoadingIndexVue = _resolveComponent("LoadingIndexVue")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.translation.places), 1),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_AppAdministratorMasterDataPlacesListFormCreateIndexVue, {
            counties: _ctx.counties,
            onGetPlaces: _ctx.getPlaces
          }, null, 8, ["counties", "onGetPlaces"])
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_AppAdministratorMasterDataPlacesListFormFilterIndexVue, { onGetPlaces: _ctx.getPlaces }, null, 8, ["onGetPlaces"])
      ]),
      (_ctx.isDataLoaded)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.places, (place) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "item",
                key: place.id
              }, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, _toDisplayString(place.content.title), 1)
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _createVNode(_component_AppAdministratorMasterDataPlacesListFormUpdateIndexVue, {
                    counties: _ctx.counties,
                    place: place,
                    onGetPlaces: _ctx.getPlaces
                  }, null, 8, ["counties", "place", "onGetPlaces"]),
                  _createVNode(_component_AppAdministratorMasterDataPlacesListFormRemoveIndexVue, {
                    place: place,
                    onGetPlaces: _ctx.getPlaces
                  }, null, 8, ["place", "onGetPlaces"])
                ])
              ]))
            }), 128)),
            (_ctx.isPaginationVisible)
              ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                  _createVNode(_component_el_pagination, {
                    background: "",
                    layout: "prev, pager, next",
                    "page-size": 24,
                    total: _ctx.pagination.total,
                    "current-page": _ctx.pagination.current_page,
                    onCurrentChange: _ctx.setPagination
                  }, null, 8, ["total", "current-page", "onCurrentChange"])
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      (_ctx.isDataLoadedAndEmpty)
        ? (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(_ctx.translation.noData), 1))
        : _createCommentVNode("", true),
      (_ctx.isLoading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
            _createVNode(_component_LoadingIndexVue)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}