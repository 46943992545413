// Store
import store from "@/store";

export interface AppPlaceCreateIndex {
  organization_id: string | string[];
  county_id: string | string[];
  content: {
    title: string;
    postCode: string;
  };
}

export class AppPlaceCreateIndex implements AppPlaceCreateIndex {
  organization_id: string | string[];
  county_id: string | string[];
  content: {
    title: string;
    postCode: string;
  };

  constructor() {
    this.organization_id = store.getters.getOrganizationMember.organization_id ?? "";
    this.county_id = "";
    this.content = {
      title: "",
      postCode: "",
    };
  }
}